import {service} from '@/api/config'
import qs from 'qs'
import axios from 'axios';
export default {
    Query: {
        //获取院校列表
        GetCollegeList(pageIndex,pageSize,data){
            return axios.post('/api/College/GetCollegeList?pageIndex='+pageIndex+'&pageSize='+pageSize, data);
        },
        //获取院校详情
        GetCollegeInfo(data){
            return axios.post('/api/College/GetCollegeInfo', data);
        },
        //根据名称搜索院校
        SearchCollegeByName(data){
            return axios.post('/api/College/SearchCollegeByName', data);
        },
        //获取院校对比
        GetCollegeCompare(data){
            return axios.post('/api/College/GetCollegeCompare', data);
        },
        //获取院校招生章程列表
        GetCollegeConstitutionList(pageIndex,pageSize,data){
            return axios.post('/api/College/GetCollegeConstitutionList?pageIndex='+pageIndex+'&pageSize='+pageSize, data);
        },
        //获取院校招生章程详情
        GetCollegeConstitutionDetail(data){
            return axios.post('/api/College/GetCollegeConstitutionDetail', data);
        },
        //获取招生计划查询参数
        GetAdmissionPlanQueryParameters(data){
            return axios.post('/api/AdmissionData/GetAdmissionPlanQueryParameters', data);
        },
        //获取招生计划
        GetAdmissionPlan(data){
            return axios.post('/api/AdmissionData/GetAdmissionPlan', data);
        },
        //获取专业录取数据查询参数
        GetAdmissionMajorQueryParameters(data){
            return axios.post('/api/AdmissionData/GetAdmissionMajorQueryParameters', data);
        },
        //获取院校录取数据
        GetAdmissionCollege(data){
            return axios.post('/api/AdmissionData/GetAdmissionCollege', data);
        },
        //获取专业录取数据
        GetAdmissionMajor(data){
            return axios.post('/api/AdmissionData/GetAdmissionMajor', data);
        },
        //获取院校排名类别列表
        GetCollegeRankCategoryList(data){
            return axios.post('/api/College/GetCollegeRankCategoryList', data);
        },
        //获取院校排名列表
        GetCollegeRankList(pageIndex,pageSize,data){
            return axios.post('/api/College/GetCollegeRankList?pageIndex='+pageIndex+'&pageSize='+pageSize, data);
        },
        //获取地区树列表
        GetAreaTreeList(data){
            return axios.post('/api/Data/GetAreaTreeList', data);
        },
        //根据名称搜索专业
        SearchMajorByName(data){
            return axios.post('/api/Major/SearchMajorByName', data);
        },
        //获取专业类详情
        GetMajorCategoryDetail(data){
            return axios.post('/api/Major/GetMajorCategoryDetail', data);
        },
        //获取专业详情
        GetMajorDetail(data){
            return axios.post('/api/Major/GetMajorDetail', data);
        },
        //获取专业开设院校列表
        GetMajorCollegeList(pageIndex,pageSize,data){
            return axios.post('/api/Major/GetMajorCollegeList?pageIndex='+pageIndex+'&pageSize='+pageSize, data);
        },
        //获取专业排名列表
        GetMajorRankList(pageIndex,pageSize,data){
            return axios.post('/api/Major/GetMajorRankList?pageIndex='+pageIndex+'&pageSize='+pageSize, data);
        },
        //获取热门专业列表
        GetMajorHotList(count){
            return axios.post('/api/Major/GetMajorHotList?count='+count);
        },
        // 专业实力 /api/College/GetCollegeMajorStrengthList
        GetCollegeMajorStrengthList(pageIndex,pageSize,data){
            return axios.post('/api/College/GetCollegeMajorStrengthList?pageIndex='+pageIndex+'&pageSize='+pageSize, data);
        },
        // 专业实力 /api/Data/GetTagList
        GetTagList(data){
            return axios.post('/api/Data/GetTagList', data);
        },
        
        // 院系 /api/College/GetCollegeDepartmentList
        GetCollegeDepartmentList(data){
            return axios.post('/api/College/GetCollegeDepartmentList', data);
        },
        // 院系 /api/College/GetCollegeDepartmentDetail
        GetCollegeDepartmentDetail(data){
            return axios.post('/api/College/GetCollegeDepartmentDetail', data);
        },
        // 院系 /api/College/GetCollegeDepartmentMajorDetail
        GetCollegeDepartmentMajorDetail(data){
            return axios.post('/api/College/GetCollegeDepartmentMajorDetail', data);
        },

        // 院校资讯列表 /api​/College/GetCollegeNewsList
        GetCollegeNewsList(pageIndex, pageSize, data){
            return axios.post('/api/College/GetCollegeNewsList?pageIndex='+pageIndex+'&pageSize='+pageSize, data);
        },
        // 院校资讯详情 /api​/College/GetCollegeNewsDetail
        GetCollegeNewsDetail(data){
            return axios.post('/api/College/GetCollegeNewsDetail', data);
        },

        // 软科专业排名 /api/Major/GetMajorRankingList
        GetMajorRankingList(pageIndex, pageSize, data){
            return axios.post('/api/Major/GetMajorRankingList?pageIndex='+pageIndex+'&pageSize='+pageSize, data);
        },

    }
}