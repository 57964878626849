import personal from "../../views/personal";
import search from "../../views/search";
export default [
    {
        path: '/',
        redirect: "/index",
        meta: { title: '' },
        component: () => import('../../views/index.vue')
    },
    {
        path: '/refresh',
        meta: { title: '' },
        component: () => import('../../views/refresh.vue')
    },
    {
        path: '/develop',
        meta: { title: '开发中' },
        component: () => import('../../views/develop.vue')
    },
    {
        path: '/index',
        meta: { title: '' },
        component: () => import('../../views/index.vue')
    },
    {
        path: '/contrast',
        meta: { title: '' },
        component: () => import('../../views/contrast/contrast.vue')
    },
    {
        path: '/login',
        meta: { title: '登录' },
        component: () => import('../../views/login.vue')
    },
    {
        path: '/noticelist',
        meta: { title: '公告通知' },
        component: () => import('../../views/news/noticelist.vue')
    },
    {
        path: '/noticedetail',
        meta: { title: '公告通知' },
        component: () => import('../../views/news/noticedetail.vue')
    },
    {
        path: '/newslist',
        meta: { title: '资讯动态' },
        component: () => import('../../views/news/newslist.vue')
    },
    {
        path: '/newsdetail',
        meta: { title: '资讯动态' },
        component: () => import('../../views/news/newsdetail.vue')
    },
    {
        path: '/setRealScore',
        meta: { title: '高考成绩' },
        component: () => import('../../views/setRealScore/index.vue')
    },
    {
        path: '/allround',
        meta: { title: '综合测评' },
        component: () => import('../../views/evaluation/allround/index.vue')
    },
    {
        path: '/allround-answer',
        meta: { title: '测评题目' },
        component: () => import('../../views/evaluation/allround/answer.vue')
    },
    {
        path: '/allround-result',
        meta: { title: '测评报告' },
        component: () => import('../../views/evaluation/allround/result.vue')
    },
    {
        path: '/online-print',
        meta: { title: '在线打印' },
        component: () => import('../../views/evaluation/online-print/index.vue')
    },
    {
        path: '/new-holland',
        meta: { title: '霍兰德测评' },
        component: () => import('../../views/evaluation/holland/index.vue')
    },
    {
        path: '/holland-answer',
        meta: { title: '测评题目' },
        component: () => import('../../views/evaluation/holland/answer.vue')
    },
    {
        path: '/holland-result',
        meta: { title: '测评报告' },
        component: () => import('../../views/evaluation/holland/result.vue')
    },
    {
        path: '/new-MBTI',
        meta: { title: 'MBTI测评' },
        component: () => import('../../views/evaluation/MBTI/index.vue')
    },
    {
        path: '/MBTI-answer',
        meta: { title: '测评题目' },
        component: () => import('../../views/evaluation/holland/answer.vue')
    },
    {
        path: '/MBTI-result',
        meta: { title: '测评报告' },
        component: () => import('../../views/evaluation/holland/result.vue')
    },
    {
        path: '/expertlist',
        meta: { title: '专家服务' },
        component: () => import('../../views/expert/expertlist.vue')
    },
    {
        path: '/expertdetail',
        meta: { title: '专家服务' },
        component: () => import('../../views/expert/expertdetail.vue')
    },
    {
        path: '/expertsettlein',
        meta: { title: '专家入驻' },
        component: () => import('../../views/expert/expertsettlein.vue')
    },
    {
        path: '/risenewslist',
        meta: { title: '升学资讯' },
        component: () => import('../../views/news/risenewslist.vue')
    },
    {
        path: '/risenewsdetail',
        meta: { title: '升学资讯' },
        component: () => import('../../views/news/risenewsdetail.vue')
    },
    {
        path: '/videolist',
        meta: { title: '视频专区' },
        component: () => import('../../views/news/videolist.vue')
    },
    {
        path: '/helpcenter',
        meta: { title: '帮助中心' },
        component: () => import('../../views/helpcenter/index.vue')
    },
    {
        path: '/content',
        meta: { title: '' },
        component: () => import('../../views/content/index.vue')
    },
    {
        path: '/search',
        meta: { title: '查询' },
        component: search,
        // redirect: "/search/newslist",
        // children: [
        //     {
        //         path:'/search/newslist',
        //         meta: { title: '资讯' },
        //         component: () => import('../../views/search/newslist.vue')
        //     },
        // ],
        // hidden: true
    },
    {
        path: '/personal',
        component: personal,
        redirect: "/userinfo",
        children: [
            {
                path:'/userinfo',
                meta: { title: '基本信息' },
                component: () => import('../../views/personal/userinfo/userinfo.vue')
            },
            {
                path:'/examinee',
                meta: { title: '高考信息' },
                component: () => import('../../views/personal/examinee/examinee.vue')
            },
            {
                path: '/openvip',
                meta: { title: '开通/激活VIP' },
                component: () => import('../../views/personal/vip/openvip.vue')
            },
            {
                path:'/setpwd',
                meta: { title: '密码设置' },
                component: () => import('../../views/personal/setpwd/setpwd.vue')
            },
            {
                path:'/setaccount',
                meta: { title: '初始账号设置' },
                component: () => import('../../views/personal/setaccount/setaccount.vue')
            },
            {
                path:'/wish-list',
                meta: { title: '志愿表' },
                component: () => import('../../views/personal/wish-list/')
            },
            {
                path:'/test-list',
                meta: { title: '测评表' },
                component: () => import('../../views/personal/test-list/')
            },
            
        ],
        hidden: true

    },
    {
        path: '/recommend-start',
        meta: {title: '志愿填报'},
        component: () => import('@/views/reporting/start'),
    },
    {
        path: '/statistics',
        meta: {title: '志愿填报'},
        component: () => import('@/views/reporting/statistics'),
    },
    {
        path: '/recommend-index',
        meta: {title: '志愿填报'},
        component: () => import('@/views/reporting/index'),
    },
    {
        path: '/wishtable',
        meta: {title: '志愿表'},
        component: () => import('@/views/reporting/wishtable'),
    },
    {
        path: '/download',
        meta: {title: '志愿表下载/打印'},
        component: () => import('@/views/reporting/download'),
    },
    {
        path: '/query/college',
        meta: {title: '查院校'},
        component: () => import('@/views/query/college'),
    },
    {
        path: '/query/compare',
        meta: {title: '院校比较'},
        component: () => import('@/views/query/college/compare'),
    },
    {
        path: '/query/collegedetail',
        meta: {title: '院校详情'},
        component: () => import('@/views/query/college/detail'),
    },
    {
        path: '/query/collegerank',
        meta: {title: '院校排行'},
        component: () => import('@/views/query/college/rank'),
    },
    {
        path: '/query/articledetail',
        meta: {title: '详情'},
        component: () => import('@/views/query/college/articledetail'),
    },
    {
        path: '/query/major',
        meta: {title: '查专业'},
        component: () => import('@/views/query/major'),
    },
    {
        path: '/query/majorlist',
        meta: {title: '查专业'},
        component: () => import('@/views/query/major/list'),
    },
    {
        path: '/query/majordetail',
        meta: {title: '专业详情'},
        component: () => import('@/views/query/major/detail'),
    },
    // {
    //     path: '/query/majorrank',
    //     meta: {title: '专业排名'},
    //     component: () => import('@/views/query/major/rank'),
    // },

    {
        path: '/query/majorrank',
        meta: {title: '专业排名'},
        component: () => import('@/views/query/major/ranking'),
    },

    {
        path: '/admissionplansearch',
        meta: {title: '招生计划'},
        component: () => import('@/views/query/college/admissionplansearch'),
    },
    {
        path: '/admissioncollegeandmajorsearch',
        meta: {title: '录取分数线'},
        component: () => import('@/views/query/college/admissioncollegeandmajorsearch'),
    },
]